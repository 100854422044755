import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import {
	createMultiStyleConfigHelpers,
	defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
	// define the part you're going to style
	container: {
		zIndex: 1000,
		top: '10px',
		bg: 'red.100',
		width: 'fit-content',
		margin: 'auto',
		position: 'absolute',
		left: ['0','7rem'],
		right: 0,
		borderRadius: '20px',
		paddingY: '5px',
		paddingX: ['10px','20px'],
		fontSize:['10px', '14px'],
	},
	title: {
		color: 'red.700', // change the color of the title text of the alert
		fontWeight: 'bold',
	},
	description: {
		fontWeight: 'semibold',
	},
});

const size = {
	md: defineStyle({
		w: 5,
		h: 5,
	}),
};

const sizes = {
	md: definePartsStyle({
		icon: size.md,
	}),
};

export const alert = defineMultiStyleConfig({
	baseStyle,
	sizes,
});
