import praktoreioIcon from '../assets/icons/articles/praktoreio.svg';
import inGrIcon from '../assets/icons/articles/in.svg';
import liberalIcon from '../assets/icons/articles/liberal.svg';
import toVimaIcon from '../assets/icons/articles/tovima.svg';
import otIcon from '../assets/icons/articles/ot.svg';
import taNeaIcon from '../assets/icons/articles/tanea.svg';
import newsBaseIcon from '../assets/icons/articles/newsbase.svg';
import newsItIcon from '../assets/icons/articles/newsit.svg';
import huffpostIcon from '../assets/icons/articles/huffpost.svg';
import efSynIcon from '../assets/icons/articles/efsyn.svg';
import cyprusNewsIcon from '../assets/icons/articles/cyprusnews.svg';
import imerisiaIcon from '../assets/icons/articles/imerisia.svg';
import thePresidentIcon from '../assets/icons/articles/thepresident.svg';
import aftodioikhshIcon from '../assets/icons/articles/autodioikhsh.svg';
import monoNewsIcon from '../assets/icons/articles/mononews.svg';
import myKosmosIcon from '../assets/icons/articles/mykosmos.svg';
import athensWestIcon from '../assets/icons/articles/athenswest.svg';
import euroNewsIcon from '../assets/icons/articles/euronews.svg';
import zooglaIcon from '../assets/icons/articles/zougla.svg';

export const articles = [
	{
		key: 'praktoreio',
		icon: praktoreioIcon,
		url: 'https://www.amna.gr/macedonia/article/740899/Omada-epistimonon-estise-enan-eufui-boitho-AI-gia-ti-sugkrisi-ton-programmaton-ton-kommaton',
	},
	{
		key: 'ingr',
		icon: inGrIcon,
		url: 'https://www.in.gr/2023/06/23/politics/kommata/texniti-noimosyni-eyfyis-voithos-ai-sygkrinei-ta-programmata-ton-kommaton/',
	},
	{
		key: 'liberal',
		icon: liberalIcon,
		url: 'https://www.liberal.gr/tehnologia/sygkrinetai-ta-programmata-ton-kommaton-meso-tehnitis-noimosynis-i-nea-efarmogi',
	},
	{
		key: 'tovima',
		icon: toVimaIcon,
		url: 'https://www.tovima.gr/2023/06/23/science/ekloges-2023-toraksero-gr-o-ai-voithos-kalpis-pou-dieksagei-debate-metaksy-ton-kommaton/',
	},
	{
		key: 'ot',
		icon: otIcon,
		url: 'https://www.ot.gr/2023/06/23/english-edition/greek-elections-2023-intelligent-ai-assistant-compares-party-programs',
	},
	{
		key: 'tanea',
		icon: taNeaIcon,
		url: 'https://www.tanea.gr/2023/06/23/politics/texniti-noimosyni-ki-anapofasistoi-eyfyis-voithos-ai-sygkrinei-ta-programmata-ton-kommaton',
	},
	{
		key: 'newsbase',
		icon: newsBaseIcon,
		url: 'https://newsbase.gr/2023/06/torakserogr-ergaleio-texnitis-noimosunis-sugkrinei-ta-programmata-ton-kommaton-3487920.html',
	},
	{
		key: 'newsit',
		icon: newsItIcon,
		url: 'https://www.newsit.gr/politikh/ekloges-2023-sygkrine-ta-programmata-ton-kommaton-meso-texnitis-noimosynis-i-protoporiaki-efarmogi-ellinon-epistimonon/3803203/',
	},
	{
		key: 'huffpost',
		icon: huffpostIcon,
		url: 'https://www.huffingtonpost.gr/entry/torakserogr-eryaleio-technetes-noemosenes-seykrinei-ta-proyrammata-ton-kommaton_gr_649572f1e4b0c0ed59b1dc8b',
	},
	{
		key: 'efsyn',
		icon: efSynIcon,
		url: 'https://www.efsyn.gr/epistimi/394932_boithos-gia-tin-kalpi-apo-tin-tehniti-noimosyni?amp',
	},
	{
		key: 'cyprus',
		icon: cyprusNewsIcon,
		url: 'https://cyprusnews.eu/vima-epistimi/14473887-%CE%B5%CE%BA%CE%BB%CE%BF%CE%B3%CE%AD%CF%82-2023-%E2%80%93-toraksero-gr-%CE%BF-ai-%CE%B2%CE%BF%CE%B7%CE%B8%CF%8C%CF%82-%CE%BA%CE%AC%CE%BB%CF%80%CE%B7%CF%82-%CF%80%CE%BF%CF%85-%CE%B4%CE%B9%CE%B5%CE%BE%CE%AC%CE%B3%CE%B5%CE%B9-%C2%ABdebate%C2%BB-%CE%BC%CE%B5%CF%84%CE%B1%CE%BE%CF%8D-%CF%84%CF%89%CE%BD-%CE%BA%CE%BF%CE%BC%CE%BC%CE%AC%CF%84%CF%89%CE%BD.html',
	},
	{
		key: 'imerisia',
		icon: imerisiaIcon,
		url: 'https://www.imerisia.gr/tehnologia/75512_torakserogr-sygkrisi-ton-programmaton-ton-kommaton-meso-tis-tehnitis-noimosynis',
	},
	{
		key: 'thepresident',
		icon: thePresidentIcon,
		url: 'https://www.thepresident.gr/2023/06/23/omada-epistimonon-estise-enan-eyfyi-voitho-ai-technitis-noimosynis-gia-ti-sygkrisi-ton-programmaton-ton-kommaton/',
	},
	{
		key: 'aftodioikhsh',
		icon: aftodioikhshIcon,
		url: 'https://www.aftodioikisinews.gr/toraksero-gr/',
	},
	{
		key: 'mononews',
		icon: monoNewsIcon,
		url: 'https://www.mononews.gr/politics/techniti-noimosini-effiis-voithos-ai-gia-ti-sigkrisi-ton-programmaton-ton-kommaton',
	},
	{
		key: 'mykosmos',
		icon: myKosmosIcon,
		url: 'https://www.mykosmos.gr/loc_mk/news/all/2144775/sugkrine-ta-programmata-twn-kommatwn-mesw-texnhths-nohmosunhs---h-prwtoporiakh-efarmogh-ellhnwn-episthmonwn.htm',
	},
	{
		key: 'athenswest',
		icon: athensWestIcon,
		url: 'https://www.athenswest.gr/newsroom/%ce%b7-helvia-ai-%ce%ba%ce%ac%ce%bd%ce%b5%ce%b9-%ce%b4%ce%b9%ce%b1%ce%b8%ce%ad%cf%83%ce%b9%ce%bc%ce%bf-%cf%84%ce%bf-toraksero-gr-%ce%bc%ce%b9%ce%b1-%cf%85%cf%80%ce%b7%cf%81%ce%b5%cf%83%ce%af%ce%b1/',
	},
	{
		key: 'euronews',
		icon: euroNewsIcon,
		url: 'https://gr.euronews.com/2023/06/23/toksero-gr-texnhth-nohmosynh-se-bohthaei-na-apofaiseis-ti-tha-plhfiseis',
	},
	{
		key: 'zoogla',
		icon: zooglaIcon,
		url: 'https://www.zougla.gr/politiki/article/omada-epistimonon-estise-enan-efi-voi8o-texnitis-noimosinis-gia-ti-sigrisi-ton-programaton-ton-komaton',
         
	},
];